import React from 'react'
import { MyBrainProvider } from './Brain'

export const wrapRootElement = ({ element }) => (
    <MyBrainProvider>{element}</MyBrainProvider>
)

//below code is needed for page transitions to work
export const shouldUpdateScroll = () => {
    return false;
};